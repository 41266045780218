import { API_PAYMENTS_URL } from '@/constants/config';
import { fetch } from '@/utils/fetch';
import { isAxiosError } from 'axios';

interface SplitDetails {
  status: string;
  totalAmount: number;
  remainingAmount: number;
  userPaymentAmount: number;
  type: string;
  numberOfSplits: number;
}

interface Props {
  orderNumber: string;
  signal: AbortSignal;
}

export const getSplitDetails = async ({
  orderNumber,
  signal,
}: Props): Promise<SplitDetails | { errorCode: number }> => {
  try {
    const response = await fetch.get(
      `${API_PAYMENTS_URL}/split-payments?reference=${orderNumber}`,
      { signal }
    );
    return response.data;
  } catch (error: unknown) {
    if (error && isAxiosError(error)) {
      return { errorCode: error?.response?.status || 500 };
    } else {
      return { errorCode: 500 };
    }
  }
};
