import styles from './SuccessSummaryTotal.module.scss';

interface Props {
  label: string;
  value: string;
}

export const SuccessSummaryTotal = ({ label, value }: Props) => {
  return (
    <div className={styles.row}>
      <h3>{label}</h3>
      <div>{value}</div>
    </div>
  );
};
