import { currency } from '@/constants/constants';
import { Nullable } from '@/interfaces/Nullable';
import formatNumber from '@/utils/formatNumber';
import styles from './CashbackEstimation.module.scss';

interface Props {
  cashbackEstimate: Nullable<string>;
}

const CashbackEstimation = ({ cashbackEstimate }: Props) => {
  if (!cashbackEstimate) return null;

  return (
    <>
      <div className={styles.root}>
        <div className={styles.cashbackEstimate}>
          <span>{formatNumber(cashbackEstimate)}</span> {currency}
        </div>
      </div>
    </>
  );
};

export default CashbackEstimation;
