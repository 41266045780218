import { useEffect, useState } from 'react';
import styles from './CountDown.module.scss';

export const CountDown = ({ startTime }: { startTime: [number, number] }) => {
  const [time, setTime] = useState(startTime[0] * 60 + startTime[1]);

  useEffect(() => {
    if (time <= 0) return;

    const intervalId = setInterval(() => {
      setTime(prevTime => prevTime - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [time]);
  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };
  return (
    <div className="countDown">
      <h2 className={styles.explanation}>Time remaining to claim your cashback</h2>
      <p className={styles.countDown}>{formatTime(time)}</p>
    </div>
  );
};
