import { API_PAYMENTS_URL } from '@/constants/config';
import { fetch } from '@/utils/fetch';

interface Params {
  orderNumber: string;
  signal: AbortSignal;
}

interface PaymentSummary {
  orderAmount: number;
  status: string;
  userPaymentAmount: number;
  userTipAmount: number;
}

export const getPaymentSummary = ({ orderNumber, signal }: Params): Promise<PaymentSummary> =>
  fetch
    .get(`${API_PAYMENTS_URL}/payments/summary?reference=${orderNumber}`, { signal })
    .then(response => response.data);
