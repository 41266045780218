import styles from './SuccessSummaryHead.module.scss';

interface Props {
  label: string;
  value: string;
  discount?: boolean;
}

export const SuccessSummaryHead = ({ label, value }: Props) => {
  return (
    <>
      <div className={`${styles.row}`}>
        <h3>{label}</h3>
        <div className={styles.successfullyPaid}>{value}</div>
      </div>
      <div className={styles.divider} />
    </>
  );
};
