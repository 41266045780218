import React, { useEffect, useState } from 'react';
import styles from './BottomSheet.module.scss';

interface Props {
  /**
   * Whether the sheet is closable.
   * If true, a close button will be rendered.
   */
  closable?: boolean;
  /**
   * Callback triggered when the sheet is closed.
   */
  onClose?: () => void;
  /**
   * Content to display inside the sheet.
   */
  children: React.ReactNode;
}

export const BottomSheet = ({ closable = true, onClose, children }: Props) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    // Trigger the slide up animation on mount.
    setVisible(true);
  }, []);

  const handleClose = () => {
    // Slide the sheet down
    setVisible(false);
    if (onClose) {
      onClose();
    }
  };

  return (
    <div className={styles.container}>
      <div className={`${styles.sheet} ${visible ? styles.visible : styles.hidden}`}>
        {closable && (
          <button onClick={handleClose} className={styles.closeButton} aria-label="Close">
            &times;
          </button>
        )}
        {children}
      </div>
    </div>
  );
};
