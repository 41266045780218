import { getPaymentSummary } from '@/api/getPaymentSummary';
import { queryKeys } from '@/constants/queryKeys';
import { useQuery } from '@tanstack/react-query';

interface Params {
  orderNumber: string;
}

export const useGetPaymentSummary = ({ orderNumber }: Params) =>
  useQuery({
    queryKey: queryKeys.paymentSummary({ orderNumber }),
    queryFn: ({ signal }) => getPaymentSummary({ orderNumber, signal }),
    staleTime: Infinity,
  });
