import kitoCoin from '@/assets/images/KitoCoin.svg';
import awani from '@/assets/images/brands/Awani.png';
import catch22 from '@/assets/images/brands/Catch22.png';
import chin from '@/assets/images/brands/ChinChin.png';
import circle from '@/assets/images/brands/Circle.png';
import eatopi from '@/assets/images/brands/Eatopi.png';
import highjoint from '@/assets/images/brands/HighJoint.png';
import operationfalafel from '@/assets/images/brands/OperationFalafel.png';
import taqado from '@/assets/images/brands/Taqado.png';
import zaroob from '@/assets/images/brands/Zaroob.png';
import CashbackEstimation from '@/components/CashbackEstimation/CashbackEstimation';
import { useLocation } from 'react-router-dom';
import styles from './SuccessTitle.module.scss';

const brands = [
  {
    name: 'Taqado',
    logo: taqado,
  },
  {
    name: 'Operation Falafel',
    logo: operationfalafel,
  },
  {
    name: 'Awani',
    logo: awani,
  },
  {
    name: 'Circle Cafe',
    logo: circle,
  },
  {
    name: 'Eatopi',
    logo: eatopi,
  },
  {
    name: 'Chin Chin',
    logo: chin,
  },
  {
    name: 'High Joint',
    logo: highjoint,
  },
  {
    name: 'Catch 22',
    logo: catch22,
  },
  {
    name: 'Zaroob',
    logo: zaroob,
  },
];

export const SuccessTitle = () => {
  const query = new URLSearchParams(useLocation().search);
  const cashbackEstimate = query.get('cashback');

  return (
    <div className={styles.root}>
      <div>
        <h1 className={styles.title}>Payment successful</h1>
        <img className={styles.kitcon} src={kitoCoin} alt="Kitopi" />
        <h2 className={styles.subtitle}>YOU’VE EARNED</h2>

        <CashbackEstimation cashbackEstimate={cashbackEstimate || ''} />

        <p className={styles.description}>Use across +200 restaurants for Dine-in & Delivery</p>
      </div>

      <div className={styles.brands}>
        <div>
          {/* First set of brand icons */}
          {brands.map(({ logo, name }) => (
            <img key={`first-${name}`} width={66} src={logo} alt={name} />
          ))}
          {/* Duplicate set for seamless infinite scroll - exact copy of the first set */}
          {brands.map(({ logo, name }) => (
            <img key={`second-${name}`} width={66} src={logo} alt={name} />
          ))}
          {/* Add the first icon again to ensure perfect looping */}
          <img key="extra-first" width={66} src={brands[0].logo} alt={brands[0].name} />
        </div>
      </div>
    </div>
  );
};
