export const {
  VITE_APP_ENV,
  VITE_INITIAL_CDN_PATH,
  VITE_WEBHOOK_BASE_URL,
  // ts-prune-ignore-next
  VITE_SENTRY_DSN,
} = import.meta.env;
export const currency = 'AED';
export const countryCode = 'AE';

export const APP_TYPE = 'kitopay';

// ts-prune-ignore-next
export const DYNATRACE = {
  PROD: 'https://js-cdn.dynatrace.com/jstag/16c6bc5bd3b/bf84985fdd/9497333ba2c4a6f9_complete.js',
  DEV: 'https://js-cdn.dynatrace.com/jstag/1623d230d17/bf16518cqz/4546e5a1f1e1fe80_complete.js',
};
