import { DYNATRACE, VITE_APP_ENV, VITE_SENTRY_DSN } from '@/constants/constants.ts';
import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.tsx';
import './index.css';
import './utils/sentryConfig.ts';

Sentry.init({ dsn: VITE_SENTRY_DSN });

const injectDynatrace = () => {
  const dynatraceScript = document.createElement('script');
  dynatraceScript.src = VITE_APP_ENV === 'prod' ? DYNATRACE.PROD : DYNATRACE.DEV;
  dynatraceScript.type = 'text/javascript';
  dynatraceScript.crossOrigin = 'anonymous';
  document.head.appendChild(dynatraceScript);
};

injectDynatrace();

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
      <App />
    </Sentry.ErrorBoundary>
  </React.StrictMode>
);
