import styles from './SuccessSummaryRow.module.scss';

interface Props {
  label: string;
  value: string;
  discount?: boolean;
}

export const SuccessSummaryRow = ({ discount, label, value }: Props) => {
  return (
    <div className={`${styles.row} ${discount ? styles.discount : ''}`}>
      <h3>{label}</h3>
      <div>{value}</div>
    </div>
  );
};
