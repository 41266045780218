import { BottomSheet } from '@/components/BottomSheet/BottomSheet';
import { useSplitDetails } from '@/hooks/useSplitDetails';
import styles from './CheckoutBottomSheetSplitInfo.module.scss';

interface Props {
  orderNumber: string;
}

export const CheckoutBottomSheetSplitInfo = ({ orderNumber }: Props) => {
  const { data, isLoading } = useSplitDetails({ orderNumber });
  const isSheetOpen = data && !('errorCode' in data);

  return (
    <div>
      {isSheetOpen && !isLoading && (
        <BottomSheet closable={false}>
          <div className={styles.splitInfoContainer}>
            <h2>Split bill available only on the App</h2>
            <p className={styles.bodyText}>
              Someone in your party started a split bill. <br />
              To join, download the app or ask the waiter to pay
            </p>
            <a
              className={styles.downloadButton}
              href="https://kitopi-rewards.go.link?adj_t=19njxhg3_19tl0v0z"
            >
              Download app
            </a>
          </div>
        </BottomSheet>
      )}
    </div>
  );
};
