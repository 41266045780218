import { currency } from '@/constants/constants';
import { useGetPaymentSummary } from '@/hooks/useGetPaymentSummary';
import formatNumber from '@/utils/formatNumber';
import { useLocation } from 'react-router-dom';
import styles from './SuccessSummary.module.scss';
import { SuccessSummaryHead } from './SuccessSummaryHead';
import { SuccessSummaryRow } from './SuccessSummaryRow';
import { SuccessSummaryTotal } from './SuccessSummaryTotal';

const Skeleton = () => {
  return <div>Loading ... </div>;
};

export const SuccessSummary = () => {
  const query = new URLSearchParams(useLocation().search);
  const orderNumber = query.get('orderNumber') || '';
  const tableNumber = query.get('tableNumber') || '';
  const counterNumber = query.get('counterNumber') || '';
  const { data: paymentSummary } = useGetPaymentSummary({ orderNumber });

  return paymentSummary ? (
    <div className={styles.successSummary}>
      <SuccessSummaryHead
        label={
          tableNumber
            ? `Table Dine in ${tableNumber}`
            : counterNumber
              ? `Counter number ${counterNumber}`
              : ''
        }
        value="Fully paid"
      />

      <SuccessSummaryRow
        label="Subtotal"
        value={`${currency} ${formatNumber(paymentSummary.orderAmount)}`}
      />

      <SuccessSummaryRow
        label="Tip amount"
        value={`${currency} ${formatNumber(paymentSummary.userTipAmount)}`}
      />
      <SuccessSummaryTotal
        label="You paid"
        value={`${currency} ${formatNumber(paymentSummary.userPaymentAmount)}`}
      />
    </div>
  ) : (
    <Skeleton />
  );
};
