import { MonitoringEvent } from '@kitopi-com/payments';
import React, { Component } from 'react';

interface PaymentsErrorBoundaryProps {
  children?: React.ReactNode;
  monitoring: ({ type, attributes }: MonitoringEvent) => void;
}

interface PaymentsErrorBoundaryState {
  hasError: boolean;
}

class PaymentsErrorBoundary extends Component<
  PaymentsErrorBoundaryProps,
  PaymentsErrorBoundaryState
> {
  constructor(props: PaymentsErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: unknown, errorInfo: React.ErrorInfo) {
    this.props.monitoring({
      type: 'error',
      attributes: {
        error: String(error),
        info: errorInfo,
        component: 'MoneyHashPayments',
        paymentSDKVersion: 'unknown',
      },
    });
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      return <div>Error occurred while processing payments.</div>;
    }
    return this.props.children;
  }
}

export default PaymentsErrorBoundary;
