import { SUCCESS_URL } from '@/App';
import { CountDown } from '@/components/CountDown';
import { SuccessSummary } from '@/components/SuccessSummary/SuccessSummary';
import { SuccessTitle } from '@/components/SuccessTitle/SuccessTitle';
import { DEFERRED_BASE_URL } from '@/constants/config';
import { useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styles from './PaymentConfirmation.module.scss';

export const PaymentConfirmation = () => {
  const screenContainerRef = useRef<HTMLDivElement>(null);
  const query = new URLSearchParams(useLocation().search);
  const orderId = query.get('order') || '';
  const brandName = query.get('brand');
  const brandId = query.get('brandId');
  const outletId = query.get('outletId');
  const tableNumber = query.get('tableNumber');
  const counterNumber = query.get('counterNumber');

  const homeLink = tableNumber
    ? `/?outletId=${outletId}&tableNumber=${tableNumber}&brandId=${brandId}`
    : counterNumber
      ? `/?outletId=${outletId}&counterNumber=${counterNumber}&brandId=${brandId}`
      : '/';

  const deferredLink = `${DEFERRED_BASE_URL}${orderId}`;

  useEffect(() => {
    (window.dataLayer || []).push({
      event: 'purchase_completed',
      orderId: orderId,
      brandName: brandName,
      success_status: SUCCESS_URL,
    });
  }, [orderId, brandName]);

  useEffect(() => {
    const element = screenContainerRef.current;
    if (!element) return;
    element.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }, []);

  return (
    <div className={styles.root} ref={screenContainerRef}>
      <div className={styles.success}>
        <div className={styles.beams}>
          <SuccessTitle />

          <Link className={styles.homeLink} to={homeLink} />

          <div className={styles.tile}>
            <CountDown startTime={[59, 59]} />

            <button className={styles.button} onClick={() => window.open(deferredLink, '_blank')}>
              Download Kitopi app to claim
            </button>

            <SuccessSummary />

            <div className={styles.disclaimer}>Ask waiter for receipt</div>
          </div>
        </div>
      </div>
    </div>
  );
};
