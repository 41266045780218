import { getSplitDetails } from '@/api/getSplitDetails';
import { queryKeys } from '@/constants/queryKeys';
import { useQuery } from '@tanstack/react-query';

export const useSplitDetails = ({ orderNumber }: { orderNumber: string }) => {
  return useQuery({
    queryKey: queryKeys.splitDetails({ orderNumber }),
    queryFn: ({ signal }) => getSplitDetails({ orderNumber, signal }),
    enabled: !!orderNumber,
  });
};
